<template>
  <div class="content-wrapper">
    <div class="content-lookup">
      <div class="lookup-left">
        <ul class="lookup-condition">
          <li class="field">
            <div class="title">
              {{ labels.bsnDate }}
            </div>
            <ul class="content">
              <li class="item dateRange">
                <input-date-range
                    v-model="inputDateRangeValue"
                    type="lookup-condition"
                />
              </li>
            </ul>
          </li>
          <li class="field">
            <div class="title">
              {{ labels.gcDiv }}
            </div>
            <ul class="content">
              <li class="item">
                <ejs-dropdownlist
                    ref="gcDiv"
                    v-model="searchConditions.gcDivList"
                    :dataSource="searchOptions.gcDivOptions"
                    :allowFiltering="false"
                    :fields="commonCodeFields"
                    cssClass="lookup-condition-dropdown"
                />
              </li>
            </ul>
          </li>
          <li class="field">
            <div class="title">
              {{ labels.partDiv }}
            </div>
            <ul class="content">
              <li class="item">
                <ejs-multiselect
                    v-model="searchConditions.partDivList"
                    cssClass="lookup-condition-multiselect"
                    :dataSource="searchOptions.partDivOptions"
                    :fields="commonCodeFields"
                    placeHolder="전체"
                    width="200"
                />
              </li>
            </ul>
          </li>
          <li class="field">
            <div class="title">
              {{ labels.storeCode }}
            </div>
            <ul class="content">
              <li class="item">
                <ejs-multiselect
                    v-model="searchConditions.storeCodeList"
                    cssClass="lookup-condition-multiselect"
                    :dataSource="searchOptions.storeCodeOptions"
                    :fields="commonCodeFields"
                    placeHolder="전체"
                    width="360"
                />
              </li>
            </ul>
          </li>
          <li class="field productName">
            <div class="title">
              {{ labels.productName }}
            </div>
            <ul class="content">
              <li class="item input">
                <input-text
                    v-model="searchConditions.productName"
                    @focus="onProductNameClear"
                />
              </li>
              <li class="item button">
                <ul class="button">
                  <li class="search">
                    <erp-button
                        button-div="GET"
                        @click.native="onSearchPopupClicked"
                    >
                      검색
                    </erp-button>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
        <div class="lookup-lookup">
          <erp-button
              button-div="GET"
              :ignore="isPopupOpened"
              :is-shortcut-button="true"
              v-on:click.native="onViewStoreSales"
          >
            조회
          </erp-button>
        </div>
      </div>
      <div class="lookup-right">
        <div class="lookup-detail">
          <erp-button
              button-div="GET"
              :is-icon-custom="true"
              @click.native="onSearchDetailPopupOpen"
          >
            상세검색
          </erp-button>
        </div>
      </div>
    </div>
    <div class="content-body">
      <ReportView ref="reportViewComponent" :isPopup="true" />
      <article class="body-article">
        <!-- 아코디언 : accordion / 닫힘 : close -->
        <section class="article-section section-01">
          <div class="section-header">
            <div class="header-left">
              <div class="header-title">전표 목록</div>
              <div class="header-caption">[{{ count }}건]</div>
            </div>
            <div class="header-right">
              <ul class="header-button">
                <li class="print">
                  <erp-button
                      button-div="FILE"
                      @click.native="excel"
                  >
                    Excel
                  </erp-button>
                </li>
              </ul>
              <ul class="header-button">
                <li class="print">
                  <erp-button
                      button-div="PRINT"
                      @click.native="print"
                      :ignore="isPopupOpened"
                      :is-shortcut-button="true"
                  >인쇄
                  </erp-button>
                </li>
              </ul>
            </div>
          </div>
          <div class="section-body">
            <div class="body-grid">
              <ejs-grid-wrapper
                  ref="storeSalesGrid"
                  :provides="grid"
                  :columns="storeSalesGridColumn"
                  :dataSource="storeSalesInfoList"
                  :groupSettings="groupSettings"
                  :pageSettings="storeSalesPageSettings"
                  :aggregates="storeSalesGridAggregates"
                  :allowResizing="true"
                  :allowExcelExport="true"
                  :allowPdfExport="true"
                  :allowGrouping="true"
                  :allowPaging="true"
                  @queryCellInfo="queryCellInfo"
                  @actionComplete="storeSalesGridActionComplete"
              />
            </div>
          </div>
        </section>
      </article>
    </div>
    <ejs-dialog
        ref="searchDetailPopup"
        :header="`상세검색`"
        :allowDragging="true"
        :showCloseIcon="true"
        width="387"
        :animationSettings="{ effect: 'None' }"
        :isModal="false"
        :visible="false"
        v-show="isSearchDetailPopupOpen"
    >
      <div class="window lookupDetail-storeSalesStatus">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-lookupDetail">
              <ul class="lookupDetail-condition">
                <li class="field">
                  <div class="title">
                    {{ labels.bsnCode }}
                  </div>
                  <ul class="content">
                    <li class="item">
                      <ejs-dropdownlist
                          v-model="searchConditions.bsnCode"
                          :dataSource="searchOptions.bsnCodeOptions"
                          :fields="commonCodeFields"
                          :allowFiltering="false"
                          cssClass="lookupDetail-condition-dropdown"
                      />
                    </li>
                  </ul>
                </li>
                <li class="field">
                  <div class="title">
                    {{ labels.dwCode }}
                  </div>
                  <ul class="content">
                    <li class="item form-group">
                      <ul class="check">
                        <li>
                          <label>
                            <input
                                type="checkbox"
                                :checked="
                                searchOptions.dwCodeOptions.length ===
                                searchConditions.dwCode.length
                              "
                                @change="dwCodeAllChecked"
                                v-model="searchConditions.dwCodeAllList"
                            />
                            <i></i>
                            <div class="label">전체</div>
                          </label>
                        </li>
                        <li
                            v-for="(dwCode, idx) in searchOptions.dwCodeOptions"
                            :key="idx"
                        >
                          <label>
                            <input
                                type="checkbox"
                                v-model="searchConditions.dwCode"
                                :value="dwCode.comCode"
                                @change="dwCodeChecked"
                            />
                            <i></i>
                            <div class="label">{{ dwCode.comName }}</div>
                          </label>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li class="field">
                  <div class="title">
                    {{ labels.slipNo }}
                  </div>
                  <ul class="content">
                    <li class="item">
                      <input-text v-model="searchConditions.slipNo" />
                    </li>
                  </ul>
                </li>
                <li class="field">
                  <div class="title">
                    {{ labels.tableName }}
                  </div>
                  <ul class="content">
                    <li class="item">
                      <input-text
                          v-model="searchConditions.tableName"
                          @keydown.native="preventEnterEvent"
                      />
                    </li>
                  </ul>
                </li>
                <li class="field groupName">
                  <div class="title">
                    {{ labels.grpName }}
                  </div>
                  <ul class="content">
                    <li class="item input">
                      <input-text
                          v-model="searchConditions.grpName"
                          @focus="onGrpNameClear"
                          @keydown.native="preventEnterEvent"
                      />
                    </li>
                    <li class="item button">
                      <ul class="button">
                        <li class="search">
                          <erp-button
                              button-div="GET"
                              @click.native="onGroupPopupSearchDetailOpen"
                          >
                            검색
                          </erp-button>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li class="field">
                  <div class="title">
                    {{ labels.taxFlag }}
                  </div>
                  <ul class="content">
                    <li class="item check">
                      <ul class="check">
                        <li>
                          <label>
                            <input
                                type="radio"
                                v-model="searchConditions.taxFlag"
                            />
                            <i></i>
                            <div class="label">ALL</div>
                          </label>
                        </li>
                        <li>
                          <label>
                            <input
                                type="radio"
                                value="true"
                                v-model="searchConditions.taxFlag"
                            />
                            <i></i>
                            <div class="label">과세</div>
                          </label>
                        </li>
                        <li>
                          <label>
                            <input
                                type="radio"
                                value="false"
                                v-model="searchConditions.taxFlag"
                            />
                            <i></i>
                            <div class="label">면세</div>
                          </label>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li class="field">
                  <div class="title">
                    {{ labels.payFlag }}
                  </div>
                  <ul class="content">
                    <li class="item check">
                      <ul class="check">
                        <li>
                          <label>
                            <input
                                type="radio"
                                v-model="searchConditions.payFlag"
                            />
                            <i></i>
                            <div class="label">ALL</div>
                          </label>
                        </li>
                        <li>
                          <label>
                            <input
                                type="radio"
                                value="true"
                                v-model="searchConditions.payFlag"
                            />
                            <i></i>
                            <div class="label">정산</div>
                          </label>
                        </li>
                        <li>
                          <label>
                            <input
                                type="radio"
                                value="false"
                                v-model="searchConditions.payFlag"
                            />
                            <i></i>
                            <div class="label">미정산</div>
                          </label>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li class="field">
                  <div class="title">
                    {{ labels.delFlag }}
                  </div>
                  <ul class="content">
                    <li class="item check">
                      <ul class="check">
                        <li>
                          <label>
                            <input
                                type="radio"
                                v-model="searchConditions.delFlag"
                            />
                            <i></i>
                            <div class="label">ALL</div>
                          </label>
                        </li>
                        <li>
                          <label>
                            <input
                                type="radio"
                                value="false"
                                v-model="searchConditions.delFlag"
                            />
                            <i></i>
                            <div class="label">정상</div>
                          </label>
                        </li>
                        <li>
                          <label>
                            <input
                                type="radio"
                                value="true"
                                v-model="searchConditions.delFlag"
                            />
                            <i></i>
                            <div class="label">삭제(VOID)</div>
                          </label>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="lookup keyColor">
              <erp-button
                  button-div="GET"
                  :ignore="isPopupOpened"
                  :is-shortcut-button="true"
                  v-on:click.native="onViewButtonClicked"
              >
                조회
              </erp-button>
            </li>
            <li class="reset">
              <erp-button
                  button-div="DELETE"
                  :is-icon-custom="true"
                  @click.native="onInitButtonClicked">
                초기화
              </erp-button>
            </li>
            <li class="close">
              <erp-button
                  button-div="CLOSE"
                  @click.native="onSearchDetailPopupClose">
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
    <group-popup
        v-if="isGroupPopupSearchDetailOpen"
        ref="groupPopupSearchDetail"
        @popupClosed="onGroupPopupSearchDetailClosed"
    ></group-popup>
    <product-code-select-popup
        v-if="isProductCodeSelectPopupOpen"
        ref="productCodeSelectPopup"
        @popupClosed="onProductCodeSelectPopupClosed"
        @popupConfirmed="onProductCodeSelectPopupConfirmed"
    ></product-code-select-popup>
  </div>
</template>

<style scoped>
body .appContent .lookup-condition .field.productName .content .item.input {width: 180px;}
body .appContent .body-article .section-body {overflow: hidden;border: none;}
</style>

<script>
import {
  DATE_FORMAT_YYYY_MM_DD,
  getFormattedDateTimePicker,
  getDayColorValue,
} from '@/utils/date';
import {
  commonCodesGetColorValue,
  commonCodesGetCommonCode,
  commonCodesGetComName,
} from "@/utils/commonCodes";
import { getStatusStores } from "@/api/salesManagement";
import { formPreventEnterEvent } from "@/utils/formUtil";
import { numberWithCommas } from "@/utils/number";
import { getAllProductClassCodes } from "@/api/productClassCode";
import InputDateRange from "@/components/common/datetime/InputDateRange";
import {
  Aggregate,
  ExcelExport,
  Filter,
  ForeignKey,
  Group,
  Page,
  PdfExport,
  Resize,
} from "@syncfusion/ej2-vue-grids";
import { Query } from "@syncfusion/ej2-data";
import commonMixin from "@/views/layout/mixin/commonMixin";
import groupPopup from "@/views/golf-reservation/popup/GroupPopup";
import productCodeSelectPopup from "../../popup/ProductCodeSelectPopup";
import ejsGridWrapper from "@/components/common/EjsGridWrapper.vue";
import GolfERPService from "@/service/GolfERPService";
import ReportView from "@/components/common/report/ReportView";
import moment from "moment";
import { mapGetters } from "vuex";
import routeViewMixin from "@/views/layout/mixin/routeViewMixin";
import InputText from "@/components/common/text/InputText";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import {getPaymentLineInfo} from "@/api/systemConfig";
import ErpButton from "@/components/button/ErpButton.vue";


export default {
  name: "StoreSalesStatusEntertainment",
  components: {
    InputText,
    groupPopup,
    productCodeSelectPopup,
    ejsGridWrapper,
    InputDateRange,
    ReportView,
    ErpButton,
  },
  mixins: [routeViewMixin, commonMixin, confirmDialogMixin],
  watch: {
    $route() {
      this.$refs.searchDetailPopup.hide();
      this.isSearchDetailPopupOpen = false;
    },
  },
  async created() {
    const nowMoment = await GolfERPService.fetchNow();
    this.now = nowMoment.toDate();
    const from = moment(this.now).format(DATE_FORMAT_YYYY_MM_DD);
    const to = nowMoment.format(DATE_FORMAT_YYYY_MM_DD);

    this.inputDateRangeValue = {
      from,
      to,
    };
    this.searchConditions.bsnStartDayOfTheWeek = commonCodesGetComName(
        "DW_CODE",
        String(new Date().getDay() + 1)
    );
    this.searchConditions.bsnEndDayOfTheWeek = commonCodesGetComName(
        "DW_CODE",
        String(new Date().getDay() + 1)
    );
    //영업구분 CLOSE 제외
    this.searchOptions.bsnCodeOptions = this.searchOptions.bsnCodeOptions.filter(
        (element) => element.comCode !== "CLOSE"
    );
    this.searchOptions.gcDivOptions.unshift({ comCode: "", comName: "전체" });
    this.searchOptions.bsnCodeOptions.unshift({ comCode: "", comName: "전체" });

    // this.getStatusStores();
  },
  computed: {
    ...mapGetters(["username"]),
    isPopupOpened() {
      return (
          this.isGroupPopupSearchDetailOpen || this.isProductCodeSelectPopupOpen
      );
    },
    datetimeString() {
      return moment(this.now).format(`YYYY-MM-DD HH:mm:ss`);
    },
    inputDateRangeValue: {
      get() {
        return {
          from: this.searchConditions.bsnStartDate,
          to: this.searchConditions.bsnEndDate,
        };
      },
      set(inputDateRangeValue) {
        this.searchConditions.bsnStartDate = inputDateRangeValue.from;
        this.searchConditions.bsnEndDate = inputDateRangeValue.to;
      },
      // deep:true,
      // immediate: true
    },
  },
  data() {
    return {
      count: 0,
      storeSalesInfoListLength: 0,
      now: null,
      labels: {
        bsnDate: "영업일자",
        gcDiv:"구분",
        partDiv: "부구분",
        priceDiv: "단가구분",
        storeCode: "매장",
        productName: "상품명",
        bsnCode: "영업구분",
        dwCode: "요일",
        classCode01: "대분류",
        classCode02: "중분류",
        classCode03: "소분류",
        slipNo: "전표번호",
        tableName: "테이블",
        grpName: "단체명",
        taxFlag: "과세/면세",
        payFlag: "정산 여부",
        delFlag: "전표 상태",
      },
      storeSalesPageSettings: { pageSize: 50 },
      storeSalesGridColumn: [
        { field: "_rid", visible: false, isPrimaryKey: true },
        { field: "groupInfo", headerText: "구분", visible: false },
        {
          headerText: "영업일자",
          field: "bsnDate",
          width: "90",
          textAlign: "center",
          type: "string",
          allowSorting: false,
        },
        {
          headerText: "요일",
          groupCode: "DW_CODE",
          isCommonCodeField: true,
          field: "dwCode",
          width: "60",
          textAlign: "center",
          type: "string",
          allowSorting: false,
        },
        {
          headerText: "영업구분",
          groupCode: "BSN_CODE",
          isCommonCodeField: true,
          field: "bsnCode",
          width: "90",
          textAlign: "center",
          type: "string",
          allowSorting: false,
        },
        {
          headerText: "부구분",
          groupCode: "PART_DIV",
          isCommonCodeField: true,
          field: "partDiv",
          width: "90",
          textAlign: "center",
          type: "string",
          allowSorting: false,
        },
        {
          field: "subGroupInfo",
          headerText: "영업일자 - 요일 - 영업구분 - 매장",
          visible: false,
        },
        {
          headerText: "매장",
          groupCode: "STORE_CODE",
          isCommonCodeField: true,
          field: "storeCode",
          width: "90",
          textAlign: "center",
          type: "string",
          allowSorting: false,
        },
        {
          headerText: "지불자 정보",
          columns: [
            {
              headerText: "지불자정보",
              field: "payerName",
              type: "string",
              width: "200",
              textAlign: "left",
            },
            {
              headerText: "단체명",
              field: "grpName",
              type: "string",
              width: "150",
              textAlign: "left",
            },
          ],
          allowEditing: false,
          textAlign: "Center",
        },
        {
          headerText: "전표내용",
          columns: [
            {
              headerText: "전표번호",
              field: "slipNo",
              width: "90",
              textAlign: "left",
              type: "string",
            },
            {
              headerText: "테이블",
              field: "tableName",
              type: "string",
              width: "70",
              textAlign: "center",
            },
          ],
          allowEditing: false,
          textAlign: "Center",
        },
        {
          headerText: "상품정보",
          columns: [
            {
              headerText: "상품명",
              field: "productName",
              type: "string",
              width: "150",
              textAlign: "left",
            },
            {
              headerText: "입력 시간",
              field: "inputTime",
              type: "string",
              width: "90",
              textAlign: "center",
            },
            {
              headerText: "과세/면세",
              groupCode: "NOTAX_CODE",
              isCommonCodeField: true,
              field: "noTaxCode",
              width: "90",
              textAlign: "Center",
              type: "string",
            },
            {
              headerText: "단가 구분",
              groupCode: "PRICE_DIV",
              isCommonCodeField: true,
              field: "priceDiv",
              width: "90",
              textAlign: "center",
              type: "string",
            },
            {
              allowEditing: false,
              headerText: "일반단가",
              field: "price",
              type: "number",
              isNumericType: true,
              width: "80",
              textAlign: "right",
            },
            {
              allowEditing: false,
              headerText: "수량",
              field: "saleQty",
              type: "number",
              isNumericType: true,
              width: "70",
              textAlign: "right",
            },
            {
              allowEditing: false,
              headerText: "총매출",
              field: "totAmt",
              type: "number",
              isNumericType: true,
              width: "70",
              textAlign: "right",
            },
          ],
          allowEditing: false,
          textAlign: "Center",
        },
        {
          field: "payFlag",
          headerText: "정산여부",
          width: "90",
          type: "boolean",
          editType: "booleanedit",
          displayAsCheckBox: true,
          allowEditing: false,
          textAlign: "Center",
        },
        {
          field: "caddieUseFlag",
          headerText: "캐디이용",
          width: "90",
          type: "boolean",
          editType: "booleanedit",
          displayAsCheckBox: true,
          allowEditing: false,
          textAlign: "Center",
        },
        {
          field: "memoContents",
          headerText: "전표 메모",
          width: "200",
          textAlign: "Center",
          type: "string",
        },
        {
          field: "delFlag",
          headerText: "삭제여부",
          width: "90",
          type: "boolean",
          editType: "booleanedit",
          displayAsCheckBox: true,
          allowEditing: false,
          textAlign: "Center",
        },
        {
          allowEditing: false,
          field: "delReason",
          headerText: "삭제사유",
          textAlign: "Center",
          type: "string",
          width: 200,
        }
      ],
      grid: [
        Aggregate,
        Filter,
        Resize,
        Group,
        ExcelExport,
        PdfExport,
        ForeignKey,
        Page,
      ],
      storeSalesGridAggregates: [
        {
          columns: [
            {
              field: "tableName",
              aggregationType: "GroupCaption",
              customAggregate: "일자별 소계",
            },
            {
              field: "saleQty",
              aggregationType: "GroupSum",
            },
            {
              field: "dcRate",
              aggregationType: "GroupAvg",
            },
            {
              field: "dcAmt",
              aggregationType: "GroupSum",
            },
            {
              field: "totAmt",
              aggregationType: "GroupSum",
            },
            {
              field: "netAmt",
              aggregationType: "GroupSum",
            },
            {
              field: "vatAmt",
              aggregationType: "GroupSum",
            },
            {
              field: "specialTax",
              aggregationType: "GroupSum",
            },
            {
              field: "eduTax",
              aggregationType: "GroupSum",
            },
            {
              field: "vilTax",
              aggregationType: "GroupSum",
            },
            {
              field: "totTaxAmt",
              aggregationType: "GroupSum",
            },
          ],
        },
        {
          columns: [
            {
              field: "slipNo",
              aggregationType: "TotalCaption",
              customAggregate: "합계",
            },
            {
              field: "saleQty",
              aggregationType: "TotalSum",
            },
            {
              field: "dcRate",
              aggregationType: "TotalAvg",
            },
            {
              field: "dcAmt",
              aggregationType: "TotalSum",
            },
            {
              field: "totAmt",
              aggregationType: "TotalSum",
            },
            {
              field: "netAmt",
              aggregationType: "TotalSum",
            },
            {
              field: "vatAmt",
              aggregationType: "TotalSum",
            },
            {
              field: "specialTax",
              aggregationType: "TotalSum",
            },
            {
              field: "eduTax",
              aggregationType: "TotalSum",
            },
            {
              field: "vilTax",
              aggregationType: "TotalSum",
            },
            {
              field: "totTaxAmt",
              aggregationType: "TotalSum",
            },
          ],
        },
      ],
      commonCodeFields: { text: "comName", value: "comCode" },
      classCodeFields: { text: "className", value: "classCode" },
      storeSalesInfoList: [],
      isSearchDetailPopupOpen: false,
      isGroupPopupSearchDetailOpen: false,
      isProductCodeSelectPopupOpen: false,
      searchOptions: {
        gcDivOptions : commonCodesGetCommonCode("GC_DIV", true),
        partDivOptions: commonCodesGetCommonCode("PART_DIV", true),
        priceDivOptions: commonCodesGetCommonCode("PRICE_DIV", true),
        bsnCodeOptions: commonCodesGetCommonCode("BSN_CODE", true),
        storeCodeOptions: commonCodesGetCommonCode("STORE_CODE", true),
        dwCodeOptions: commonCodesGetCommonCode("DW_CODE", true),
        classCode01Options: [],
        classCode02Options: [],
        classCode03Options: [],
        classCode02Query: new Query().where("parentCode", "equal", ""),
        classCode03Query: new Query().where("parentCode", "equal", ""),
      },
      searchConditions: {
        //상세검색
        bsnCode: "", // 영업구분
        dwCodeAllList: true, // 요일 전체
        dwCode: commonCodesGetCommonCode("DW_CODE", true).map(
            (item) => item.comCode
        ), //요일 개별
        classCode01: "ALL", // 대분류
        classCode02: "ALL", // 중분류
        classCode03: "ALL", // 소분류
        tableName: null, // 테이블
        slipNo: null, // 전표번호
        grpNo: null, // 단체번호
        grpName: null, // 단체명
        taxFlag: null, // 과세/면세
        payFlag: null, // 정산 여부
        delFlag: 'false', // 전표 상태
        //영업일자
        bsnStartDate: moment()
            .subtract(1, "month")
            .add(1, "day")
            .format(DATE_FORMAT_YYYY_MM_DD),
        bsnEndDate: moment().format(DATE_FORMAT_YYYY_MM_DD),
        bsnStartDayOfTheWeek: null,
        bsnEndDayOfTheWeek: null,
        storeCodeList: null,
        gcDivList: "",
        partDivList: null,
        priceDivList: ["RECEPTN"],
        productName: null,
        productCode: null,
      },
      groupSettings: {
        showDropArea: false,
        columns: ["groupInfo" /*, "subGroupInfo" */],
      },

      visiblePrintPaper: false,
    };
  },
  methods: {
    preventEnterEvent: formPreventEnterEvent,
    queryCellInfo(args) {
      const {
        cell,
        column: { field },
        data,
      } = args;
      if (field === "totAmt") {
        cell.style.fontWeight = "bold"; // 총금액 컬럼 폰트굵기 css지정
      }
      if (field === "payFlag" && data.payFlag) {
        // 정산여부 폰트색상 css지정
        cell.style.backgroundColor = "#FF9999";
      }
      if (field === "noTaxCode" && data.noTaxCode) {
        cell.style.color = commonCodesGetColorValue(
            "NOTAX_CODE",
            data.noTaxCode
        );
      }
      if (data.delFlag) {
        // 삭제여부가 true인 경우 상품명, 총금액 폰트색상 css지정
        if (field === "productName" || field === "totAmt") {
          cell.style.color = "red";
        }
      }
      if (field === "dwCode") {
        cell.style.color = getDayColorValue(data.dwCode, data.bsnCode);
      }
      if (field === "bsnCode") {
        cell.style.color = commonCodesGetColorValue(
            "BSN_CODE",
            data.bsnCode
        );
      }
    },
    onClassCode01Changed() {
      this.searchOptions.classCode02Query = new Query().where(
          "parentCode",
          "equal",
          this.$refs.classCode01Dropdown.ej2Instances.value
      );
    },
    onClassCode02Changed() {
      this.searchOptions.classCode03Query = new Query().where(
          "parentCode",
          "equal",
          this.$refs.classCode02Dropdown.ej2Instances.value
      );
    },
    onSearchDetailPopupOpen() {
      this.isSearchDetailPopupOpen = true;
      this.$refs.searchDetailPopup.show();

      this.getAllProductClassCodes();
    },
    onViewButtonClicked() {
      if (!this.isValidBsnDate()) {
        return;
      }
      if (!this.isValidDwcode()) {
        return;
      }
      this.getStatusStores();
    },
    onInitButtonClicked() {
      this.searchConditions.bsnCode = "";
      this.searchConditions.classCode01 = null;
      this.searchConditions.classCode02 = null;
      this.searchConditions.classCode03 = null;
      this.searchConditions.tableName = null;
      this.searchConditions.slipNo = null;
      this.searchConditions.grpNo = null;
      this.searchConditions.grpName = null;
      this.searchConditions.taxFlag = null;
      this.searchConditions.payFlag = null;
      this.searchConditions.delFlag = 'false';
      this.searchConditions.dwCodeAllList = true;
      this.searchConditions.dwCode = commonCodesGetCommonCode(
          "DW_CODE",
          true
      ).map((item) => item.comCode);
    },
    onSearchDetailPopupClose() {
      this.$refs.searchDetailPopup.hide();
      this.isSearchDetailPopupOpen = false;
    },
    onGroupPopupSearchDetailOpen() {
      this.isGroupPopupSearchDetailOpen = true;
      this.$nextTick(() => {
        this.$refs.groupPopupSearchDetail.openPopup({
          grpNo: this.searchConditions.grpNo,
          grpName: this.searchConditions.grpName,
        });
      });
    },
    onSearchPopupClicked() {
      this.openProductCodeSelectPopup();
    },
    onProductCodeSelectPopupClosed() {
      this.isProductCodeSelectPopupOpen = false;
    },
    onPrintPaperClose() {
      this.visiblePrintPaper = false;
    },
    onGroupPopupSearchDetailClosed(event) {
      this.isGroupPopupSearchDetailOpen = false;
      if (event.selectedGroup) {
        this.searchConditions.grpNo = event.selectedGroup.grpNo;
        this.searchConditions.grpName = event.selectedGroup.grpName;
      }
    },
    onProductCodeSelectPopupConfirmed(popupData) {
      this.searchConditions.productCode = popupData.productCode;
      this.searchConditions.productName = popupData.productName;
    },
    onBsnEndDateChange(args) {
      if (args.value == null) {
        this.searchConditions.bsnEndDate = null;
        this.searchConditions.bsnEndDayOfTheWeek = null;
      } else {
        this.searchConditions.bsnEndDate = getFormattedDateTimePicker(
            args,
            "yyyy-MM-dd"
        );
        this.searchConditions.bsnEndDayOfTheWeek = commonCodesGetComName(
            "DW_CODE",
            String(args.value.getDay() + 1)
        );
        if (
            this.searchConditions.bsnStartDate > this.searchConditions.bsnEndDate
        ) {
          this.searchConditions.bsnStartDate = this.searchConditions.bsnEndDate;
        }
      }
    },
    onProductNameClear() {
      this.searchConditions.productCode = null;
      this.searchConditions.productName = null;
    },
    onGrpNameClear() {
      this.searchConditions.grpNo = null;
      this.searchConditions.grpName = null;
    },
    onViewStoreSales() {

      this.searchConditions.bsnStartDate = this.inputDateRangeValue.from;
      this.searchConditions.bsnEndDate = this.inputDateRangeValue.to;

      if (!this.isValidBsnDate()) {
        return;
      }
      if (!this.isValidDwcode()) {
        return;
      }
      this.getStatusStores();
    },
    isValidBsnDate() {
      if (!this.searchConditions.bsnStartDate) {
        this.errorToast(
            this.$t("main.validationMessage.requiredMessage", ["영업일자"])
        );
        return false;
      }
      if (!this.searchConditions.bsnEndDate) {
        this.errorToast(
            this.$t("main.validationMessage.requiredMessage", ["종료일자"])
        );
        return false;
      }

      return true;
    },
    isValidDwcode() {
      if (
          !this.searchConditions.dwCodeAllList &&
          this.searchConditions.dwCode.length < 1
      ) {
        this.errorToast("요일을 하나 이상 선택해 주세요");
        return false;
      }
      return true;
    },
    openProductCodeSelectPopup() {
      this.isProductCodeSelectPopupOpen = true;
      this.$nextTick(() => {
        this.$refs.productCodeSelectPopup.showPopup(
            this.searchConditions.productName
        );
      });
    },
    dwCodeAllChecked(args) {
      if (args.target.checked) {
        this.searchConditions.dwCode = commonCodesGetCommonCode(
            "DW_CODE",
            true
        ).map((item) => item.comCode);
      } else {
        this.searchConditions.dwCode = [];
      }
    },
    dwCodeChecked() {
      if (
          this.searchConditions.dwCode.length ===
          this.searchOptions.dwCodeOptions.length
      ) {
        this.searchConditions.dwCodeAllList = true;
      } else {
        this.searchConditions.dwCodeAllList = false;
      }
    },
    async getAllProductClassCodes() {
      try {
        const {
          value: { productClasses01, productClasses02, productClasses03 },
        } = await getAllProductClassCodes();
        this.searchOptions.classCode01Options = productClasses01;
        this.searchOptions.classCode02Options = productClasses02;
        this.searchOptions.classCode03Options = productClasses03;
        this.searchOptions.classCode01Options.unshift({ classCode: "ALL", className: "전체" });
        this.searchOptions.classCode02Options.unshift({ classCode: "ALL", className: "전체" });
        this.searchOptions.classCode03Options.unshift({ classCode: "ALL", className: "전체" });
      } catch (e) {
        throw new Error("error loading class codes");
      }
    },
    setGroupInfo(storeSalesInfoList) {
      //그룹별 데이터 출력을 위해 groupInfo 생성
      this.storeSalesInfoList = storeSalesInfoList.map((item, idx) => {
        const dwCode = commonCodesGetComName("DW_CODE", item.dwCode.toString());
        const bsnCode = commonCodesGetComName("BSN_CODE", item.bsnCode);
        item.groupInfo = `${item.bsnDate} (${dwCode}) ${bsnCode}`;
        item.dwCode = item.dwCode.toString(); // foreign key 컬럼 적용을 위해
        item.subGroupInfo = `${item.groupInfo} ${item.storeCode}`;
        item._rid = idx;
        item.totAmt = Number(item.price) * Number(item.saleQty);
        return item;
      });

    },
    getStatusStores() {
      const bsnCode =
          this.searchConditions.bsnCode === ""
              ? undefined
              : this.searchConditions.bsnCode;
      const classCode01 = (this.searchConditions.classCode01 === "ALL" ? null : this.searchConditions.classCode01) || undefined; // paramSerializer를 사용한 경우, undefined 처리해야 빈 파라미터로 요청되지 않음
      const classCode02 = (this.searchConditions.classCode02 === "ALL" ? null : this.searchConditions.classCode02) || undefined;
      const classCode03 = (this.searchConditions.classCode03 === "ALL" ? null : this.searchConditions.classCode03) || undefined;
      const delFlag = this.searchConditions.delFlag || undefined;
      const dwCode = this.searchConditions.dwCode || undefined;
      const grpNo = this.searchConditions.grpNo || undefined;
      const grpName = this.searchConditions.grpName || undefined;
      const taxFlag = this.searchConditions.taxFlag || undefined;
      const payFlag = this.searchConditions.payFlag || undefined;
      const tableName = this.searchConditions.tableName || undefined;
      const slipNo = this.searchConditions.slipNo || undefined;
      const priceDiv = this.searchConditions.priceDivList || undefined;
      const productId = this.searchConditions.productCode
          ? this.searchConditions.productCode
          : undefined;
      const productName = this.searchConditions.productName
          ? this.searchConditions.productName
          : undefined;
      const storeCodes = this.searchConditions.storeCodeList || undefined;
      const partDivs = this.searchConditions.partDivList || undefined;
      const gcDiv = this.searchConditions.gcDivList;
      getStatusStores(
          bsnCode,
          this.searchConditions.bsnEndDate,
          this.searchConditions.bsnStartDate,
          classCode01,
          classCode02,
          classCode03,
          delFlag,
          dwCode,
          grpNo,
          grpName,
          taxFlag,
          payFlag,
          priceDiv,
          productId,
          productName,
          slipNo,
          storeCodes,
          tableName,
          partDivs,
          gcDiv
      )
          .then((response) => {
            this.setGroupInfo(response.value.salesReportByStoreList);

            this.storeSalesInfoListLength =
                response.value.salesReportByStoreList.length;
          })
          .catch((error) => {
            console.error("getStatusStores.err ===>", error);
          });
    },
    storeSalesGridActionComplete() {
      this.count = numberWithCommas(
          this.$refs.storeSalesGrid?.getGridBatchCount() || 0
      );
    },
    excel() {
      this.$refs.storeSalesGrid.excelExport();
    },
    print: async function () {
      const options = [
        {
          field: "storeCode",
          comCode: "STORE_CODE",
          format: "comCode",
        },
        {
          field: "noTaxCode",
          comCode: "NOTAX_CODE",
          format: "comCode",
        },
        {
          field: "priceDiv",
          comCode: "PRICE_DIV",
          format: "comCode",
        },
      ];

      const result = this.$refs.storeSalesGrid.getGridBatchData(options);
      const [{ tsConfSanctnDetail }] = (await getPaymentLineInfo(true)).value.sanctnList.filter((data)=> data.sanctnLineId === 7  );
      if (result.length < 1) {
        return this.errorToast(this.$t("report.popupMessage.noData"));
      }

      const searchOptionsList = [
        {
          key: this.labels.bsnDate,
          value:
              this.searchConditions.bsnStartDate +
              " ~ " +
              this.searchConditions.bsnEndDate,
        },
        {
          key: this.labels.productName,
          value: this.searchConditions.productName,
        },
        {
          key: this.labels.bsnCode,
          value: commonCodesGetComName(
              "BSN_CODE",
              this.searchConditions.bsnCode
          ),
        },
        {
          key: this.labels.dwCode,
          value:
              this.searchConditions.dwCode.length < 7
                  ? this.searchConditions.dwCode
                      .map((item) => commonCodesGetComName("DW_CODE", item))
                      .join(", ")
                  : null,
        },
        {
          key: this.labels.priceDiv,
          value: this.searchConditions.priceDivList != null
              ? this.searchConditions.priceDivList.map((item)=>commonCodesGetComName("PRICE_DIV",item)).join(", "):'전체'
        },
        {
          key: this.labels.classCode01,
          value: this.searchConditions.classCode01
              ? this.searchOptions.classCode01Options.find(
                  (item) => (item.classCode === this.searchConditions.classCode01) && (this.searchConditions.classCode01 !== 'ALL')
              )?.className
              : null,
        },
        {
          key: this.labels.classCode02,
          value: this.searchConditions.classCode02
              ? this.searchOptions.classCode02Options.find(
                  (item) => (item.classCode === this.searchConditions.classCode02) && (this.searchConditions.classCode02 !== 'ALL')
              )?.className
              : null,
        },
        {
          key: this.labels.classCode03,
          value: this.searchConditions.classCode03
              ? this.searchOptions.classCode03Options.find(
                  (item) => (item.classCode === this.searchConditions.classCode03) && (this.searchConditions.classCode03 !== 'ALL')
              )?.className
              : null,
        },
        {
          key: this.labels.slipNo,
          value: this.searchConditions.slipNo,
        },
        {
          key: this.labels.tableName,
          value: this.searchConditions.tableName,
        },
        {
          key: this.labels.grpName,
          value: this.searchConditions.grpName,
        },
        {
          key: this.labels.taxFlag,
          value:
              this.searchConditions.taxFlag !== null
                  ? this.searchConditions.taxFlag === "true"
                      ? "과세"
                      : "면세"
                  : null,
        },
        {
          key: this.labels.payFlag,
          value:
              this.searchConditions.payFlag !== null
                  ? this.searchConditions.payFlag === "true"
                      ? "정산"
                      : "미정산"
                  : null,
        },
        {
          key: this.labels.delFlag,
          value:
              this.searchConditions.delFlag !== null
                  ? this.searchConditions.delFlag === "true"
                      ? "삭제(VOID)"
                      : "정상"
                  : null,
        },
        {
          key: this.labels.storeCode,
          value: this.searchConditions.storeCodeList != null
              ? this.searchConditions.storeCodeList.map((item)=>commonCodesGetComName("STORE_CODE",item)).join(", "):'전체'
        }
      ];

      const searchOptions = searchOptionsList
          .filter((item) => !!item.value)
          .map((item) => item.key + ": " + item.value)
          .join(", ");

      const reportData = {
        reportJson: {
          jsonData: result,
          selectSanctnLineList: tsConfSanctnDetail,
          username: this.username,
          uniqueCode: this.$options.name,
          searchOptions,
        },
      };

      this.$refs.reportViewComponent.postReport(reportData, this.$options.name);
    },
    numberWithCommas,
  },
};
</script>
